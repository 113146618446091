<template>
    <div class="p-relative" v-if="dataSource">
        
        <Toolbar 
            :menu="menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <div class="white-panel-items">
            <div class="wp-row">
                <div class="info-panel-text info-panel-text-uppercase"><b>{{ $t("Шаблон") }} {{isNewRecord ? $t('Новый') : dataSource.NameRu}}</b></div>
            </div>
        </div>

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>

            <v-tab v-if="dataSource.RawSchema">
                {{ $t("Предпросмотр") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Общие сведения") }}</div>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Наименование") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.NameRU ? dataSource.NameRU : $t("не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.NameRU"
                                                hide-details
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t('Форма_отчета') }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group">

                                                <div @click="$refs.schemaRef.click()">
                                                    <input
                                                        type="file"
                                                        accept=".srpt"
                                                        ref="schemaRef"
                                                        @change="onSchemeFilesPicked"
                                                        style="display: none"
                                                    />
                                                    <v-icon v-if="!isViewMode" small>
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                
                                                <div class="cloud-file-list-box">

                                                    <div v-if="!dataSource.Schema" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                                    <v-row 
                                                        v-else
                                                        no-gutters
                                                    >
                                                        <v-file-item
                                                            :name="dataSource.Schema.Name"
                                                            :size="dataSource.Schema.Length"
                                                            col-width="12"
                                                            :hide-load="true"
                                                            :isReadOnly="true"
                                                        />
                                                    </v-row>

                                                </div>                
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t('Шаблон_отчета') }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group">

                                                <div @click="$refs.templateRef.click()">
                                                    <input
                                                        type="file"
                                                        accept=".xlsx,.xls"
                                                        ref="templateRef"
                                                        @change="onTemplateFilesPicked"
                                                        style="display: none"
                                                    />
                                                    <v-icon v-if="!isViewMode" small>
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                
                                                <div class="cloud-file-list-box">
                                                    
                                                    <div v-if="!dataSource.Template" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                                    <v-row 
                                                        v-else
                                                        no-gutters
                                                    >
                                                        <v-file-item
                                                            :name="dataSource.Template.Name"
                                                            :size="dataSource.Template.Length"
                                                            col-width="12"
                                                            :hide-load="true"
                                                            :isReadOnly="true"
                                                        />
                                                    </v-row>

                                                </div>                
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    
                        <v-col v-if="isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Доступ_к_шаблону") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Доступ_к_шаблону") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData" v-if="!dataSource.EnterpriseIds || dataSource.EnterpriseIds.length == 0">
                                                {{ $t("Не_указано") }}
                                            </div>
                                            <div class="onlyReadData more-per-lab-wrap" v-else>
                                                <template v-for="enterpriseId in dataSource.EnterpriseIds">
                                                    <v-enterprise-chip
                                                        :key="enterpriseId"
                                                        :name="getEnterpriseName(enterpriseId)"
                                                    />
                                                </template>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>
            <v-tab-item>
                <template v-if="dataSource.RawSchema">
                    <ReportInteraction 
                        :id="dataSource.id"
                        :rawScheme="dataSource.RawSchema"
                        :isViewMode="true"
                    />
                </template>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';
import ReportInteraction from '@/components/tabs/report-interaction-tab.vue'

export default {
    name: "ReportQueryTemplateCard",
    components: {
        Toolbar,
        ReportInteraction
    },
    props: {
        id: { type: String, required: true }
    },
    computed: {
        isNewRecord() {
            return this.dataSource.Id=='00000000-0000-0000-0000-000000000000';
        },
        menu() {
            if (this.isViewMode)
                return [this.editbutton, this.accessbutton];
            else 
                return [this.savebutton, this.saveandclosebutton];
        },
        selectEnterprisesTooltip(){
            return i18n.t("Выбрать_организации_у_которых_будет_доступ_к_документу");
        },
    },
    data() {
        return {
            tab: null,
            isViewMode: true,
            dataSource: null,
            enterprises:[],
            editbutton: {Action:"Edit",Icon:"edit",Text: i18n.t("Редактировать"),ToolTip:i18n.t("Редактировать_шаюлон")},
            savebutton: {Action:"Save",Icon:"save",Text: i18n.t("Сохранить"),ToolTip:i18n.t("Сохранить_изменения_в_шаблоне")},
            accessbutton: {Action: "Access", Icon: "edit_access", Text: i18n.t("Доступ_к_шаблону"), ToolTip: i18n.t("Доступ_к_шаблону")},
            saveandclosebutton: {Action:"SaveAndClose",Icon:"save_and_close",Text: i18n.t("Сохранить_и_закрыть"),ToolTip:i18n.t("Сохранить_изменения_в_шаблоне_и_закрыть_карточку")},
            schemaFile: null,
            templateFile: null
        }
    },
    methods: {
        async onTemplateFilesPicked(e) {
            let attachment = Array.from(e.target.files)[0];
            let checkResult = await this.validateFile(attachment);
            if (checkResult.success) {
                this.dataSource.Template = sys.prepareAttachment(attachment, this.$notify);
                this.templateFile = attachment;
                this.dataSource.IsTemplateChanged = true;
            }
            else 
                this.$notify.alert(checkResult.message);
            e.target.value = '';
        },
        async onSchemeFilesPicked(e) {
            let attachment = Array.from(e.target.files)[0];
            let checkResult = await this.validateFile(attachment);
            if (checkResult.success) {
                this.dataSource.Schema = sys.prepareAttachment(attachment, this.$notify);
                this.schemaFile = attachment;
                let reader = new FileReader();
                reader.onload = this.handleFileLoad;
                reader.readAsText(e.target.files[0])
                this.dataSource.IsSchemaChanged = true;
            }
            else 
                this.$notify.alert(checkResult.message);
            e.target.value = '';
        },
        handleFileLoad(event){
            this.dataSource.RawSchema = event.target.result;
        },
        validateFile(file) {
            if (file.name.length > 100)
                return { success: false, message: i18n.t("Превышена_допустимая_длина_имени_файла_limit.message", { limit: 100 }) };

            if (['!!'].some(seq => file.name.includes(seq)))
                return { success: false, message: i18n.t("Недопустимое_имя_файла") };

            if (file.size > 15728640)
                return { success: false, message: i18n.t("Превышен_лимит_размера_вложения_limit.message", { limit: '15 Мб' }) };

            var fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase();

            if (!['srpt','xlsx','xls'].includes(fileExtention))
                return { success: false, message: i18n.t("Недопустимое_расширение_файла") };

            return { success: true, message: null };
        },
        async getTemplate() {
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            if (this.id==='new'){
                this.dataSource = {
                    Id:'00000000-0000-0000-0000-000000000000',
                    NameRU: '',
                    Schema: null,
                    RawSchema: null,
                    IsSchemaChanged: false,
                    Template: null,
                    IsTemplateChanged: false,
                    EnterpriseIds: []
                };
                this.isViewMode = false;
            }
            else {
                let url = `/api/open?type=Documents.ReportQueryTemplate&id=${this.id}`;  
                let response = await httpAPI({
                    url,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response) {
                    this.dataSource = response.data.payload.Data.Object;
                    this.isViewMode = true;
                }
            }
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        getEnterpriseName(id){
            let ent = this.enterprises.find(x=>x.id == id);
            if (ent)
                return ent.name;
            else 
                return 'Не найдено';
        },
        async saveTemplate(edit_after_save) {
            let validateResult = this.$refs.form.validate();

            if (!validateResult) {
                this.$notify.alert(this.$t("Не_заполнены_обязательные_поля"));
                return;
            }

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let formdata = new FormData();
            if (this.schemaFile)
                formdata.append(`file1`, this.schemaFile);
            if (this.templateFile)
                formdata.append(`file1`, this.templateFile);
            let dto = {
                Id:this.dataSource.Id, 
                NameRU:this.dataSource.NameRU, 
                Schema: this.dataSource.Schema,
                Template: this.dataSource.Template,
                IsTemplateChanged: this.dataSource.IsTemplateChanged, 
                IsSchemaChanged: this.dataSource.IsSchemaChanged
            };
            let url = `/api/ems/savereportquerytemplate?dtoJson=${JSON.stringify(dto)}`;  
            let response = await httpAPI({
                url,
                method: 'POST',
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            if (response) {
                this.$notify.success(response.data.payload.Message);
                this.dataSource = response.data.payload.Data.Object;
                this.isViewMode = edit_after_save;
            }
        },
        async onToolbarClick(e, btn) {
            await this[btn.Action]();
        },
        //#region Действия (тулбар)
        async Save() {
            await this.saveTemplate(false);
        },
        async SaveAndClose() {
            await this.saveTemplate(true);
        },
        Edit() {
            this.isViewMode = false;
        },
        async Access() {
            try {
                let selected = this.dataSource.EnterpriseIds ?? [];
                let dlgResult = await this.$store.dispatch('dialogs/selectEnterprise/open', { multyselect: true, selected });
                selected = dlgResult.map(x => x.id);
                let response = await httpAPI({
                    url:'/api/ems/reportquerytemplateaccess',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json','isCommon': true },
                    data: { Content : JSON.stringify({id: this.dataSource.Id, enterpriseIds: selected}) },
                });
                if (response) {
                    this.$notify.success(response.data.payload.Message);
                    this.dataSource.EnterpriseIds = selected;
                }
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async UpdateEnterprises(){
            this.enterprises = await this.$store.dispatch('references/getEnterprises');
        }
    },
    created() {
        (async() => {
            await this.UpdateEnterprises();
            await this.getTemplate();
            
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.validate();
        })();      
    },
    updated() {
        if(this.dataSource !== null) {
            this.$refs?.form?.validate();
        }  
    }
}
</script>